.in-header-btn-box-hb {
  width: 100%;
  float: left;
  text-align: right;
}

.in-header-btn-box-hb button.inner-header-btn-hb {
  background: #1a2b57;
  border: solid 1px white;
  color: rgba(255, 255, 255, 1);
  padding: 8px 20px !important;
  border-radius: 4px !important;
  z-index: 1 !important;
  font-size: 16px;

  cursor: pointer;
}

.left-hb {
  display: flex;
  align-items: center;
}

.icon-hb {
  margin-left: 48px;
}

.title-hb {
    margin-left: 8px;
    color: white;
    font-size: 16px;
}

@media (min-width: 481px) and (max-width: 576px) {
  
  .icon-hb {
    margin-left: 18px;
  }
  
}


@media (min-width: 320px) and (max-width: 480px) {
   .main-header img.logo {
    width: 40px;
    height: 40px;
  } 
  

  .main-header img {
    width: 25px;
    height: 25px;
  
}

.icon-hb{
  margin-left: 8px;
}

.title-hb{
  font-size: 12px;
}

.in-header-btn-box-hb button.inner-header-btn-hb{
  padding: 4px 8px !important;
  font-size: 12px;
}
}


@media (max-width: 319px) {
  
  .main-header img.logo {
    width: 30px;
    height: 30px;
  } 
  

  .main-header img {
    width: 20px;
    height: 20px;
  
}

.icon-hb{
  margin-left: 4px;
}

.title-hb{
  font-size: 10px;
}

.in-header-btn-box-hb button.inner-header-btn-hb{
  padding: 4px 8px !important;
  font-size: 10px;
}


}
