.admin-inner-container-am {
  text-align: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 30px 00px 50px;
  position: absolute;
  z-index: 9;
  background: #fff;
  border-radius: 4px;
}

.adm_container-am {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1.adm_h1-am {
  color: #262626;
  font-size: 22px;
  text-align: left;
  margin-bottom: 0px;
  margin-left: 14px;
}

.brand-icon-am {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.main-col1-am {
  width: 100%;
  float: left;

  background: #f4f4f4;
  display: flex;
  justify-content: center;
  min-height: 110px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  transition: 200ms ease-in-out;
}
.main-col1-am:hover {
  transform: scale(1.01);
}

h4.link-heading-am {
  float: left;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 27px;
  text-align: left;
  font-size: 20px;

}

.link-bo.am {
  padding-left: 0px;
}

a.adm_link1.amt {
  font-weight: inherit;
}

a.admmin_link1.amt {
  font-weight: normal;
}

p.adm-p-link.margin.am {
  margin-bottom: 0px;
}

.col-box.amt {
  width: 90%;
}

.arrow-am {
  display: flex;
  cursor: pointer;
}


.adm_link1.amt {
  color: #262626;
}

.adm_link1.amt:hover {
  color: #F5843E;
}

.link-customer-s {
  width: 100%;
  float: left;
}

@media (min-width: 1281px) {
  
  
}



@media (min-width: 1025px) and (max-width: 1280px) {
  .admin-inner-container-am{
    width: 85%;
  }
  
}


@media (min-width: 992px) and (max-width: 1024px) {
  
  .admin-inner-container-am{
    width: 85%;
  }
  
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .admin-inner-container-am{
    position: unset;
    margin-top: -100px;
  }
  
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  
}




@media (min-width: 576px) and (max-width: 767px) {
  .admin-inner-container-am{
    position: unset !important;
    z-index: unset;
  }

  
}

@media (min-width: 481px) and (max-width: 575px) {
  
  .admin-inner-container-am{
    position: unset !important;
    z-index: unset;
  }

  .adm_container-am{
    padding: 20px;
  }
  
}



@media (min-width: 320px) and (max-width: 480px) {
  .admin-inner-container-am{
    position: unset !important;
    z-index: unset;
  }

  .in-header-wrap.ihb {
    margin-top: 70px !important;
  }

  .adm_link1.amt{
    font-size: 12px;
  }

  h4.link-heading-am{
    font-size: 14px;
  }

  .adm_container-am{
    padding: 20px;
  }
}


@media (max-width: 319px) {
  
  .admin-inner-container-am{
    position: unset !important;
    z-index: unset;
  }

  .in-header-wrap.ihb {
    margin-top: 60px !important;
  }

  .adm_link1.amt{
    font-size: 10px;
  }

  h4.link-heading-am{
    font-size: 12px;
  }

  h1.adm_h1-am {
  font-size: 16px;
  } 

  .adm_container-am{
    padding: 15px;
  }
  
}


