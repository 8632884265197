.custom_info_wrap {
    width: 100%;
    float: left;
}
button.button_main {
    background: #F5843E;
    border: solid 2px #F5843E;
    color: rgba(255,255,255,1);
    padding: 10px 16px !important;
    font-size: 16px;
    position: relative;
    font-weight: bold;
    cursor: pointer;
}
.input_email {
    width: 80%;
    float: left;
}
.input_email>div {
    width: 100%;
}
.input_email input {
    width: 100%;
    border: none;
    border-radius: 4px;
}
.button_search {
    width: 20%;
    float: left;
    text-align: right;
}
.wrap_search_box {
    border: 1px solid #cccccc;
    width: 100%;
    float: left;
    border-radius: 4px;
}
.tabs_wrap {
    width: 100%;
    float: left;
    margin-top: 30px;
}
.tabs_wrap ul.nav-tabs {
    border: 0px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}
.tabs_wrap ul.nav-tabs li.nav-item button {
    padding: 0px 0px;
    font-size: 20px;
    color: #000000;
    font-weight: bold;
    border-radius: 0px !important;
    border: 0px;
    line-height: 20px;
}
.tabs_wrap ul.nav-tabs li.nav-item {
    margin: 0px 60px;
}
.tabs_wrap ul.nav-tabs li.nav-item button.nav-link.active {
    color: #F6853F;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: solid 2px #F6853F;
}
.tabs_wrap ul.nav-tabs li.nav-item button:hover {
    color: #F6853F;
    border-bottom: solid 2px #F6853F;
}
.tab_box_table{
    width: 100%;
    float: left;
    margin-top: 40px;
}
.tab_box_table table.table-p {
    width: 99.8% !important;
    text-align: center;
    border-collapse: collapse;
}
thead.heading-tab{
    width: 100%;
    float: left;
    background: #1A2B57;
}
thead.heading-tab tr{
    display: flex;
}
.tab_box_table thead.heading-tab th {
    display: flex;
    width: 100% !important;
    justify-content: center;
    color: #fff;
    padding: 14px 8px;
}
tbody.bodyoftable {
    max-height: 50vh !important;
    overflow: auto !important;
    width: 100% !important;
    float: left;
}
.tab_box_table table.table-p tbody.bodyoftable tr {
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
    display: flex !important;
    justify-content: space-around !important;
    width: 100% !important;
    float: left;
}
.tab_box_table table.table-p td {
    border: 1px solid #dddddd;
    text-align: center;
    font-size: 15px;
    padding-left: 14px;
    word-break: break-word;
}
.tab_box_table table.table-p td {
    border-bottom: 1px solid #dddddd !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    border: 1px solid #dddddd;
    text-align: center;
    font-size: 15px;
    padding-left: 14px;
    word-break: break-word;
    padding: 14px 8px;
    width: 100%;
}
.pagination_wrap {
    width: 100%;
    float: left;
    margin: 30px 0px;
}
.unused_point{
    width: 100%;
    float: left;
    text-align: center;
    font-size: 20px !important;
    margin-top: 20px;
}
