.rd_btn_container {
    display: flex;
    width: 100% !important;
    margin-left: 0px;
}
.rd_btn_container span.checkmark {
    margin-top: -2px !important;
}


/* The container */
.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left;
      margin-right: 10px;
    margin-top: 5px;
  
  }
  
  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    border: solid 1px #ccc;
  }
  
  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: #4989FE;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .checkmark:after {
       top: 8px;
      left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
  /******************** close radio button styling ***********************/
  
  
  .rd-container {
      display: flex;
      margin-top: 20px;
  }
  label.rd_btn_label {
      /* font-size: 18px; */
      font-weight: bold;
      padding: 10px;
      box-sizing: border-box;
      color: #666666;
  }
  .rd_btn_container {
      margin-left: 20px;
      width: 300px;
  }
  