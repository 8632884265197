.time-box {
    width: 100%;
    float: left;
}
.time-inn {
    width: 100%;
    float: left;
}
.time-left {
    width: 46%;
    float: left;
}
.preview_container.without_icon {
    width: 100%;
    padding-right: 10px !important;
    padding-left: 10px !important;
    border-radius: 5px;
    height: 40px !important;
    line-height: 40px !important;
}
.time-pick-wrap {
    width: 100%;
    float: left;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 40px;
}
.react_times_button.time_picker_preview {
    background-color: transparent;
    border-radius: 5px;
    box-shadow: unset !important;
}
.time-left.margin {
    float: right;
}
.time_picker_container.dark {
    background: transparent !important;
}
.time-box .preview_container {
    width: 100%;
    padding-right: 10px !important;
    padding-left: 10px !important;
    border-radius: 5px;
    height: 40px !important;
    line-height: 40px !important;
}
.dark .time_picker_preview .preview_container svg {
    left: unset;
    right: 3px;
    top: 7px;
}
.outside_container.active {
    width: 300px !important;
    position: relative;
    display: flex;
    top: -30px;
}

.MuiInput-underline:before {
    border-bottom: 0px !important;
}
.MuiInput-underline:after {
    border: 0px !important;
}
.time-box .MuiInput-formControl.MuiInputBase-adornedEnd {
    border: solid 1px #ccc;
    border-radius: 4px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
}
.MuiPickersToolbar-toolbar {
    background: #1a2b57 !important;
    color: #fff;
}
 h2.MuiTypography-root {
    color: #fff !important;
}
 .MuiPickersClockPointer-pointer{
    background-color: #1a2b57 !important
}
.MuiPickersClock-pin{
    background-color: #1a2b57 !important
}
.MuiPickersClockPointer-noPoint{
    background-color: #1a2b57 !important
}
 .MuiPickersClockPointer-thumb{
    border: 14px solid #1a2b57 !important
}
span.MuiButton-label{
    color: #1a2b57 !important;
}
.time-box span.material-icons.MuiIcon-root {
    color: #F5843E;
}
.time-box .MuiIconButton-root{
    padding: 5px;
}
p.error-message-time{
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}
