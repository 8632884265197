.question-head {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 100px;
}
h3.question-h {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #000 !important;
}
.question-container {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}
.main-question {
    width: 100%;
    float: left;
}
.question-box {
    width: 100%;
    float: left;
    padding-top: 70px;
}
.qustion-inner-b {
    width: 100%;
    float: left;
}
.question-input-one {
    width: 25%;
    float: left;
}
.question-input-one .label-input {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
    color: #666666 !important;
}


.qustion-inner-b .label-input {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
    color: #666666 !important;
}

.question-input-one .drop_down_input_container {
    border: 1px solid #cccccc;
    border-radius: 4px !important;
    height: 47px;
}




.qustion-inner-b .drop_down_input_container {
    border: 1px solid #cccccc;
    border-radius: 4px !important;
    height: 47px;
    width: 84%;
}

.rider-c-box {
    width: 100%;
    float: left;
    padding-top: 30px;
    height: 70vh;
    margin-bottom: 50px;
}
button {
    cursor: pointer;
}
.rider-main-text-b textarea.ltai_input {
    color: #000 !important;
}
.pagination-box {
    width: 100%;
    float: left;
    padding-bottom: 30px;
}
.pagination-inner-box {
    width: 100%;
    float: left;
    text-align: center;
}
.box-pagination {
    width: 100%;
    float: left;
}
.box-pagination .next-btn {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.cont1 a {
    background: #F5843E;
    color: #fff;
    text-decoration: none !important;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 16px;
}
.cont1 {
    width: 110px;
}
.count-btn a {
    color: #000;
    text-decoration: none !important;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 16px;
}
a.active-page {
    background: #F5843E;
    color: #fff;
}





@media (min-width: 481px) and (max-width: 575px) {
  
    .question-box{
        padding-top: 35px;
    }

    .qustion-inner-b{
        margin-bottom: 20px;
    }

    .qustion-inner-b .drop_down_input_container{
        width: 75%;
    }

    h2.upload-header-head{
        font-size: 24px;
    }
    
  }

@media (min-width: 320px) and (max-width: 480px) {

    .question-box{
        padding-top: 25px;
    }

    .qustion-inner-b{
        margin-bottom: 20px;
    }

  
    h2.upload-header-head{
        font-size: 20px;
    }

    h3.question-h{
        font-size: 20px;
    }

    .question-head{
        padding-top: 75px;
    }

    .rider-c-box{
        padding-top: 0px !important;
    }
  }

  
  @media (max-width: 319px) {
    
    .question-box{
        padding-top: 20px;
    }

    .qustion-inner-b{
        margin-bottom: 15px;
    }



    h2.upload-header-head{
        font-size: 14px;
    }

    h3.question-h{
        font-size: 14px;
    }
    .question-head{
        padding-top: 50px;
    }

    .rider-c-box{
        padding-top: 0px !important;
    }
  }