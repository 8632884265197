.loading-btn {
    text-align: center;
}
.spinner-btn-border.loading-btn-color {
    color: #ffffff;
}
.spinner-btn-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

  @keyframes spinner-border {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }