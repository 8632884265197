.ltai_container{
    display: flex;
    /* background-color: cadetblue; */
    /* align-items: center; */
}
.ltai_container .ltai_label{
    /* font-size: 18px; */
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
}
.ltai_container .ltai_input_container{

    margin-left: 20px;
}
.ltai_container .ltai_input_container .ltai_input{
    /* all:unset; */
    box-sizing: border-box;
    border: 1px solid #cccccc;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    outline: none;
    height: 48px;
    color: #666666;
    vertical-align: middle;
    font-family: 'Poppins', sans-serif;
    width: 300px;
    height: 100px;
    line-height: 1.6em;
    box-sizing: border-box;
    resize: none;
}
.ltai_container .ltai_input_container .ltai_error_text{
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
}