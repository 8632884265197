.upload-header-wrap {
    width: 100%;
    float: left;
}
.upload-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 60px;
    box-sizing: border-box;
    flex-direction: row;
}
.upload-header-box {
    display: flex;
    flex-direction: row;
}
.upload-header-box1 {
    width: 100%;
    float: left;
    padding: 30px 0px;
}
.upload-header-back {
    width: 30%;
    float: left;
}
.upload-header-back span.material-icons {
    font-size: 32px;
    color: #000000;
}
h2.upload-header-head {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
}
.upload-header-heading {
    width: 100%;
    float: left;
    text-align: center;
}