.gpp-box {
    width: 100%;
    float: left;
}
.new-btn-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.gpp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;
    flex-direction: column;
}
.gpp-in-container {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 380px;
}
.gpp-radio-btn {
    width: 100%;
    float: left;
    text-align: left;
}
.gpp-radio-btn.display {
    display: flex;
    align-items: baseline;
    /* margin-bottom: 20px; */
}
.gpp-input-text {
    width: 90%;
    float: left;
    margin-left: 35px;
}
.gpp-box-in {
    width: 100%;
    float: left;
}
.gpp-box-in.margin {
    display: flex;
}
.gpp-left-input {
    width: 100%;
}
.gpp-date-pic .box-date-picker input {
    width: 89%;
}
.gpp-right-side {
    width: 100%;
    float: left;
}
.gpp-right-side input.box_input {
    width: 100%;
}
.gpp-point-b {
    width: 100%;
}
.gpp-point-b .box_input_container {
    margin-bottom: 0px;
}
.gpp-date-pic .box-date-picker span {
    height: 39px;
}
.gpp-date-pic input.dapi_input.box-input-date {
    height: 39px;
}
button.gpp-button {
    background: #F5843E;
    border: solid 2px #F5843E;
    color: rgba(255,255,255,1);
    padding: 8px 20px !important;
    border-radius: 8px !important;
    z-index: 1 !important;
    font-size: 16px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}
.gpp-box-in textarea.tai_input {
    width: 100% !important;
    border-radius: 4px;
}

.gpp-send-btn {
    width: 100%;
    float: left;
    margin-top: 30px;
}
.gpp-point-b input#si_input {
    width: 100%;
    height: 39px;
    border-radius: 5px;
}
.gpp-input-text .box_input_container .box_input {
    border: solid 1px #ccc;
}
.gpp-input-text .drop_down_input_container {
    border: solid 1px #cccc;
}
label.gpp-date-label {
    font-size: 14px;
    width: 100%;
    float: left;
    margin-bottom: 10px;
    font-weight: 600;
    color: #666666;
    padding-top: 15px;
}
label.radio-container {
    color: #666666 !important;
    font-weight: 600 !important;
}
.gpp-input-text input {
    width: 100%;
    height: 39px;
    border-radius: 5px;
    margin-bottom: 12px;
}
 .erpc_header {
    margin-bottom: 30px;
    text-align: center;
}


.gpp-radio-btn     .rd_btn_container {
    margin-left: 0px;
}






@media (min-width: 1281px) {
  

    
  }

  
  @media (min-width: 1025px) and (max-width: 1280px) {
 
    
  }
  

  
  @media (min-width: 992px) and (max-width: 1024px) {
    .erpc_header h1 {
        font-size: 30px !important;
    }
    
  }

    
  @media (min-width: 768px) and (max-width: 991px) {
   
    .erpc_header h1 {
        font-size: 30px !important;
    }

    .gpp-box{
        margin-top: 30px;
    }

    .erpc_header{
        text-align: left;
    }
}
  

  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
 
    
  }
  
 
  
  @media (min-width: 576px) and (max-width: 767px) {
    .erpc_header h1 {
        font-size: 26px !important;
    }

    .gpp-box{
        margin-top: 30px;
    }

     .erpc_header{
        text-align: left;
    }

    
  }

  @media (min-width: 481px) and (max-width: 575px) {
    
    .erpc_header h1 {
        font-size: 26px !important;
    }

    .gpp-box{
        margin-top: 30px;
    }

    .erpc_header{
        text-align: left;
    }

    
}

  

  @media (min-width: 320px) and (max-width: 480px) {
    
    .erpc_header h1 {
        font-size: 26px !important;
    }
 

    .erpc_header h1 {
        font-size: 20px !important;
    }

    .gpp-box{
        margin-top: 20px;
    }

    .erpc_header{
        text-align: left;
    }

    .gpp-box-in .gpp-input-text {
        margin-left: 0px;
        width: 100%;
    }

  }


  @media  (max-width: 319px) {

      
    .erpc_header h1 {
        font-size: 16px !important;
    }
    .gpp-box{
        margin-top: 20px;
    }

    .erpc_header h1 {
        font-size: 16px !important;
    }

   

    .erpc_header{
        text-align: left;
    }

    .gpp-box-in .gpp-input-text {
        margin-left: 0px;
        width: 100%;
    }

  }