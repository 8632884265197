a {
    cursor: pointer !important;
}
.in-header-wrap {
    width: 100%;
    float: left;
    padding: 30px 0px;
}
.in-header-container {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}
.in-header-left {
    width: 50%;
    float: left;
}
.in-header-wrap {
    margin-top: 112px !important;
}
.in-header-btn-box {
    width: 100%;
    float: left;
    text-align: right;
}
.in-header-wrap h1 a.in-header-link {
    color: #232319;
    text-decoration: none;
    font-size: 30px;
}
.in-header-btn-box button.inner-header-btn {
    background: #F5843E;
    border: solid 2px #F5843E;
    color: rgba(255,255,255,1);
    padding: 8px 20px !important;
    border-radius: 8px !important;
    z-index: 1 !important;
    font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
        cursor: pointer;
  }

  button:focus {
    outline: 0px !important;
}
.version-get-b {
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 100px;
    text-align: center;
    box-shadow: 2px 3px 6px #000;
    min-height: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    background: #fff;
}
.version {
    color: #000;
    font-size: 20px;
    font-weight: bold;
}